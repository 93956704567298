<template>
    <div class="title" v-bind:style="styleObject" :class="template">
        <h1>
            {{title}}
        </h1>
    </div>
</template>

<script>
export default {
    name:"PageTitle",
    props: ['title', 'bg', 'tag', 'template'],
    data(){
        return {
            styleObject: {
                background: this.bg ?  `url("${this.bg}")` : 'url("/images/header-bg.png")',
                backgroundSize: 'cover',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
div{
    display: flex;
    flex-direction: column;
    height: 70vh;
    align-items: center;
    justify-content: center;
    max-height: 625px;
    color: #fff;
}
h1{
    text-transform: uppercase;
}

@media (max-width: $tablet) {
    div{
        margin-top: 0;
        height: 90vh;
        max-height: unset;
        &::before{
            margin-top: 60px;
            height: 90vh;
            max-height: unset;
        }
        &.property{
            height: 80vh;
            &::before{
                height: 80vh;
            }
        }
    }
    h1{
        padding-top: 0;
    }
}
@media (max-width: $mobile) {
    div{
        height: 100vw;
        &::before{
            height: 100vw;
        }
        &.property{
            height: 80vh;
            &::before{
                height: 80vh;
            }
        }
    }
    h1{
        font-size: 55px;
        line-height: 1;
    }
    .tag{
        margin-left: 2rem;
    }
}
</style>
