<template>
    <div id="press">
        <PageTitle :title="title" />
        <div v-if="!$store.getters.getLoaded">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div v-else class="press">
            <div class="press-container">
                <div data-aos="fade-up" :data-aos-delay="$store.getters.isMobile ? 0 : (i%3+1)*400" class="press-item" v-for="(article, i) in press" :key="i">
                    <a class="overlay-container" :href="article.ArticleURL" target="_blank" rel="noopener noreferrer">
                        <img class="prees-image" :src="url+article.Image.url" :alt="article.Title">
                        <div class="overlay">
                            <a class="btn" :href="article.ArticleURL" target="_blank" rel="noopener noreferrer">
                                Read Article <img src="/images/icon/arrow-white.svg" alt="Read More" />
                            </a>
                        </div>
                    </a>
                    <h3>
                        {{article.Title}}
                    </h3>
                </div>
            </div>
        </div>
        <hr>
        <div class="newsletter">
            <div class="heading">
                <h3>SIGN UP FOR OUR</h3>
                <h1>
                    NEWSLETTER
                </h1>
                <p>
                    Sign up to receive our monthly newsletter where you can access valuable information about buying and selling real estate. You can also find out about the latest market trends, pocket listings, hot buys and more. 
                </p>
            </div>
            <form method="post" @submit.prevent="handleSubmit">
                <input type="hidden" name="form-name" value="newsletter-signup"/>
                <div class="form">
                    <div class="input-group">
                        <label for="FirstName">
                            First Name 
                        </label>
                        <input v-model="data.firstName" id="firstName" name="FirstName" type="text">
                    </div>
                    <div class="input-group">
                        <label for="LastName">
                            Last Name 
                        </label>
                        <input v-model="data.lastName" id="LastName" name="LastName" type="text">
                    </div>
                    <div class="input-group">
                        <label for="Email">
                            Email Address *
                        </label>
                        <input v-model="data.email" required id="email" name="Email" type="email">
                    </div>
                    <div class="input-group">
                        <label for="Interests">
                            Interests:
                        </label>
                        <div class="interests">
                            <div class="check-box-group">
                                <input type="checkbox" v-model="data.NewListings" id="newListing" name="NewListings" value="New Listings">
                                <label for="NewListings">New Listings</label>
                            </div>
                            <div class="check-box-group">
                                <input type="checkbox" v-model="data.IndustryTrends" id="industryTrends" name="IndustryTrends" value="Industry Trends">
                                <label for="IndustryTrends">Industry Trends</label>
                            </div>
                            <div class="check-box-group">
                                <input type="checkbox" v-model="data.ComingSoon" id="comingSoon" name="ComingSoon" value="Coming Soon & Pocket Listings">
                                <label for="ComingSoon">Coming Soon & Pocket Listings</label>
                            </div>
                            <div class="check-box-group">
                                <input type="checkbox" v-model="data.Tips" id="tips" name="Tips" value="Tips & Advice">
                                <label for="Tips">Tips & Advice</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="submit">
                    <button v-on:click="submitForm" class="btn-submit">
                        Submit <img src="/images/icon/arrow-white.svg" alt="Read More" />
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import Spinner from 'vue-simple-spinner'

export default {
    name: "Press",
    components: {
        PageTitle,
        Spinner
    },
    data(){
        return{
            title:"Press",
            url: "https://strapi.uptowncreative.io",
            emailSubmitTo: 'info@waltersplaxen.com',
            data: {
                _subject: 'Newsletter Sign Up - waltersplaxen.com',
                _cc:'Shauna@waltersplaxen.com,Nicole@waltersplaxen.com',
                email: '',
                firstName: '',
                lastName: '',
                NewListings: false,
                IndustryTrends: false,
                ComingSoon: false,
                Tips: false,
            },
        }
    },
    mounted:function(){
        if(!this.$store.getters.loaded || this.$store.getters.checkExpiryDate){
            this.$store.dispatch('updateProperties');
        }
    },
    methods:{
        clearForm(){
            this.data = {
                _subject: 'Newsletter Sign Up - waltersplaxen.com',
                _cc:'Shauna@waltersplaxen.com,Nicole@waltersplaxen.com',
                email: '',
                firstName: '',
                lastName: '',
                NewListings: false,
                IndustryTrends: false,
                ComingSoon: false,
                Tips: false,
            }
        },
        submitForm(){
            this.axios({
                method: 'post',
                url: `https://formsubmit.co/ajax/${this.emailSubmitTo}`,
                data: this.data
            })
            .then(() =>{
                console.log('test')
                this.$toast.success("Success!", {
                    position: "top-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                })
                this.clearForm();
            })
            .catch(
                () => {
                    this.$toast.error("Error submitting, please try again.", {
                        position: "top-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                }
            )
        },
        handleSubmit(){
            console.log('submit')
        }
    },
    computed: {
        press:function(){
            return this.$store.getters.press;
        },
    },
    metaInfo: {
        title: 'Press',
        titleTemplate: '%s | WALTERS | PLAXEN',
        meta: [{
            name: 'description', 
            content: "WALTERS | PLAXEN"
        }]
    },
}
</script>

<style lang="scss" scoped>
.press{
    text-align: center;
}
.press-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 30px;
    margin: 80px 160px;
    .prees-image{
        width: 100%;
    }
}


.press-item{
    h3{
        text-align: left;
        text-transform: uppercase;
    }
}

.newsletter{
    margin: 100px;
}
.heading{
    text-align: center;
    h3, h1, p{
        margin: 0;
    }
    h1{
        margin: 20px 0;
    }
    max-width: 900px;
    margin: 0 auto;
}

.form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    max-width: 900px;
    margin: 0 auto;
}
.input-group{
    display: block;
    margin-top: 40px;
    input{
        display: block;
        width: 100%;
        height: 40px;
        border: 1px solid #000;
    }
}

.interests{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    input{
        margin-right: 2px;
    }
}

.check-box-group{
    display: flex;
    text-align: left;
    .input-group{
        margin-top: 5px;
    }
    input{
        height: 15px;
        width: unset;
    }
    label{
        display: inline;
    }
}

.submit{
    text-align: center;
    margin:0 auto;
    margin-top: 60px;
}

@media (max-width: $tablet) {
    .press-container{
        grid-template-columns: 1fr 1fr;
        margin: 80px 60px;
    }
}
@media (max-width: $mobile) {
    .press-container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        margin: 80px 40px;
    }
    .press-item{
        h3{
            text-align: center;
        }
    }
    .newsletter{
        margin: 40px;
    }
    .heading{
        text-align: center;
        h3, h1, p{
            margin: 0;
        }
        h1{
            font-size: 40px;
            margin: 5px 0 10px;
        }
    }
    .form{
        grid-template-columns: 1fr;
        gap: 10px;
    }
    .interests{
        grid-template-columns: 1fr;
        gap: 10px 0;
    }

    .submit{
        margin-top: 40px;
    }
}
</style>